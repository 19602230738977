//@ts-check
import { Badge, Box, Grid, HStack } from "@chakra-ui/layout";
import { motion } from "framer-motion";
import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Section } from "../pages";

const Models = () => {
  const { atlas, doruk, kent, sultan, m2010, all } = useStaticQuery(
    graphql`
      query {
        atlas: allItem(filter: { model: { regex: "/(Atlas|ATLAS|atlas)/" } }) {
          edges {
            node {
              id
            }
          }
        }
        doruk: allItem(filter: { model: { regex: "/(Doruk|DORUK|doruk)/" } }) {
          edges {
            node {
              id
            }
          }
        }
        kent: allItem(filter: { model: { regex: "/(Kent|KENT|kent)/" } }) {
          edges {
            node {
              id
            }
          }
        }
        sultan: allItem(filter: { model: { regex: "/(SULTAN|Sultan|sultan)/" } }) {
          edges {
            node {
              id
            }
          }
        }
        m2010: allItem(filter: { model: { regex: "/(2010)/" } }) {
          edges {
            node {
              id
            }
          }
        }
        all: allItem {
          edges {
            node {
              id
            }
          }
        }
      }
    `
  );

  return (
    <Section name="model" value="Yedek Parçalar">
      <Grid templateColumns={{ md: "repeat(6,1fr)", base: "1fr 1fr" }} gridGap="2">
        <ModelItem
          to="/products"
          render={
            <StaticImage
              src="../images/allModels.jpg"
              objectFit="contain"
              alt="allModels"
              aspectRatio={1.3}
              quality={1}
              imgStyle={{ borderRadius: "3px" }}
            ></StaticImage>
          }
          count={all?.edges?.length}
          value="HEPSİ"
        ></ModelItem>
        <ModelItem
          to="/products/sultan"
          render={
            <StaticImage
              src="../images/sultan.jpg"
              objectFit="contain"
              alt="dsa"
              aspectRatio={1.3}
              quality={1}
              imgStyle={{ borderRadius: "3px" }}
            ></StaticImage>
          }
          count={sultan?.edges?.length}
          value="Sultan"
        ></ModelItem>
        <ModelItem
          to="/products/kent"
          render={
            <StaticImage
              src="../images/kent.jpg"
              objectFit="contain"
              alt="dsa"
              aspectRatio={1.3}
              quality={1}
              imgStyle={{ borderRadius: "3px" }}
            ></StaticImage>
          }
          count={kent?.edges?.length}
          value="Kent"
        ></ModelItem>
        <ModelItem
          to="/products/doruk"
          render={
            <StaticImage
              src="../images/doruk.jpg"
              objectFit="contain"
              alt="dsa"
              aspectRatio={1.3}
              quality={1}
              imgStyle={{ borderRadius: "3px" }}
            ></StaticImage>
          }
          count={doruk?.edges?.length}
          value="Doruk"
        ></ModelItem>
        <ModelItem
          to="/products/atlas"
          count={atlas?.edges?.length}
          render={
            <StaticImage
              src="../images/atlas.jpg"
              objectFit="contain"
              alt="dsa"
              aspectRatio={1.3}
              quality={1}
              imgStyle={{ borderRadius: "3px" }}
            ></StaticImage>
          }
          value="Atlas"
        ></ModelItem>
        <ModelItem
          to="/products/m2010"
          render={
            <StaticImage
              src="../images/m2010.jpg"
              objectFit="cover"
              alt="dsa"
              aspectRatio={1.3}
              quality={1}
              imgStyle={{ borderRadius: "3px" }}
            ></StaticImage>
          }
          count={m2010?.edges?.length}
          value="M2010"
        ></ModelItem>
      </Grid>
    </Section>
  );
};

const ModelItem = ({ value, render, to, count }) => (
  <motion.div whileHover={{ y: -10 }}>
    <Box as={Link} to={to} cursor="pointer" position="relative">
      {render}
      <HStack position="absolute" left="1" bottom="3">
        <Badge shadow="xl">{value}</Badge>
        {count && (
          <Badge shadow="xl" colorScheme="green">
            {count} Ürün
          </Badge>
        )}
      </HStack>
    </Box>
  </motion.div>
);

export default Models;
