//@ts-check
import { Badge, Box, Grid, Text } from "@chakra-ui/layout";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import Models from "../components/Models";

const IndexPage = () => {
  return (
    <Grid
      templateColumns={{ base: "1fr", md: "1fr 1fr 1fr 1fr 1fr" }}
      templateAreas={{
        base: '"model" "about" "project" "contact"',
        md: ' "model model model model model" "about about about project project" "contact contact contact project project"',
      }}
      gap={{ md: "3", base: "1" }}
      px={{ md: "7", base: "3" }}
      py={{ md: "7", base: "3" }}
    >
      <About></About>
      <Project></Project>
      <Models></Models>
      <Contact></Contact>
    </Grid>
  );
};
export const Section = ({ value, name, children }) => {
  return (
    <Box gridArea={name} bg="white" borderWidth="thin" borderColor="gray.100" fontWeight="600" p="5" rounded="md">
      <Box userSelect="none">
        <Badge
          fontSize={{ md: "1.2rem", base: "1rem" }}
          bg="white"
          color="gray.700"
          borderBottomWidth="2px"
          borderRadius="none"
          borderBottomColor="gray.700"
          mb="5"
        >
          {value}
        </Badge>
      </Box>

      {children}
    </Box>
  );
};
const About = () => {
  return (
    <Section name="about" value="Hakkımızda">
      <StaticImage src="../images/about.png" objectFit="contain" alt="dsa" layout="fullWidth" aspectRatio={2}></StaticImage>
      <Text mt="5" fontWeight="medium">
        Firmamızın temeli 1980 yılında babamız Hasan Şevket SOMAY tarafından atıldı. 1987 yılından itibaren Otokar yetkili servisi
        olarak hizmet vermekteyiz. Servisimiz Esenyurt Evren Oto Sanayisinde olup, 1000 m2 servis, 400 m2 yedek parça 300 m2
        kapalı alan olarak toplam 1700 m2 alan üzerine kuruludur. Müşterilere SOMAYLAR ekolu altında yaklaşık 40 yıla aşkın bir
        süredir 2 kuşak olarak hizmet vermekteyiz. Servisimizde tam teşekkürlü olarak kaporta, boya ve mekanik olarak hizmet
        vermekteyiz.
      </Text>
    </Section>
  );
};

const data = [
  { url: "https://www.facebook.com/somaylar.otokar.34/posts/865712177510599", height: 572 },
  { url: "https://www.facebook.com/somaylar.otokar.34/posts/804098200338664", height: 486 },
  { url: "https://www.facebook.com/somaylar.otokar.34/posts/808699726545178", height: 562 },
  { url: "https://www.facebook.com/somaylar.otokar.34/posts/842909996457484", height: 645 },
  { url: "https://www.facebook.com/somaylar.otokar.34/posts/795646224517195", height: 645 },
];

const Project = () => {
  const ref = React.useRef(null);
  const [width, setWidth] = React.useState(1);

  React.useEffect(() => {
    setWidth(ref.current.clientWidth);
  }, []);
  return (
    <Section name="project" value="PROJELERİMİZ">
      <Box overflowY="auto" h={{ md: "800px", base: "auto" }} w="100%" ref={ref}>
        {data.map(({ url, height }) => (
          <iframe
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            src={`https://www.facebook.com/plugins/post.php?href=${url}&width=${width}&show_text=true&height=${height}&appId`}
            width={width}
            height={(width / 500) * height * 1.1}
            style={{ border: "none", overflow: "hidden" }}
            scrolling="no"
            frameBorder="0"
            allowFullScreen={true}
          ></iframe>
        ))}
      </Box>
    </Section>
  );
};

const Contact = () => {
  return (
    <Section name="contact" value="İLETİŞİM">
      <ContactItem title="Adres" value="Evren Oto Sanayi Sitesi 10 Blok No: 7-8-9 Esenyurt / İSTANBUL"></ContactItem>
      <ContactItem title="Email" value="somaylar.otokar34@gmail.com"></ContactItem>
      <ContactItem title="Telefon" value="0 212 672 28 97"></ContactItem>
    </Section>
  );
};

const ContactItem = ({ title, value }) => (
  <>
    <Text fontSize="0.8rem" fontWeight="600" my="1">
      {title}
    </Text>
    <Text mb="2" fontWeight="400">
      {value}
    </Text>
  </>
);
export default IndexPage;
