//@ts-check
import { Badge, Box, HStack, Text } from "@chakra-ui/layout";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

const ProductItem = ({ gatsbyImage, name, model, price, id, baseFontSize = "1rem" }) => {
  const image = getImage(gatsbyImage);
  return (
    <motion.div whileHover={{ y: -10 }}>
      <Link to={`/product/${id}`}>
        <Box
          fontSize={baseFontSize}
          userSelect="none"
          overflow="hidden"
          borderWidth="thin"
          borderColor="gray.100"
          borderRadius="md"
          cursor="pointer"
        >
          <GatsbyImage image={image} alt={name.trim()} draggable="false" />

          <Box py="4" px="3">
            <HStack alignItems="center" justifyContent="space-between">
              <Box fontWeight="700" isTruncated color="gray.700">
                <Text fontSize="1em">{name.toLocaleUpperCase("tr")}</Text>
              </Box>
              {/* <Badge fontSize="1em">{price}₺</Badge> */}
            </HStack>
            <HStack mt="2" d="flex" alignItems="baseline">
              <Badge px="2" colorScheme="teal" fontSize=".8em">
                {model}
              </Badge>
            </HStack>
          </Box>
        </Box>
      </Link>
    </motion.div>
  );
};

export default ProductItem;
