//@ts-check
import { Button } from "@chakra-ui/button";
import { Badge, Box, Grid, HStack, Text, VStack } from "@chakra-ui/layout";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Models from "../components/Models";
import Others from "../components/Others";
const number = "905442625353";
const mail = "somaylar.otokar34@gmail.com";
const ProductTemplate = ({ data }) => {
  const [count, setCount] = useState(1);
  const { gatsbyImage, name, model, price, id } = data.allItem.edges[0].node;
  const image = getImage(gatsbyImage);

  return (
    <Box p={{ md: "7", base: "3" }}>
      <Helmet>
        <title>{name.toLocaleUpperCase("tr")} | SOMAYLAR</title>
      </Helmet>
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 2fr " }}
        gap={{ md: "10", base: "2" }}
        p={{ md: "10", base: "0" }}
        bg="white"
        borderWidth="thin"
        borderColor="gray.100"
        mb="3"
        borderRadius="md"
      >
        <Box p={{ md: "0", base: "5" }} borderRadius="10px" overflow="hidden">
          <GatsbyImage image={image} alt={name.trim()} draggable="false" imgStyle={{ borderRadius: "5px" }} />
        </Box>

        <Box>
          <VStack px={{ md: "2", base: "7" }} py={{ md: "0", base: "0" }} spacing="6" alignItems="flex-start" mb="12">
            <Box fontWeight="800" isTruncated fontSize="2xl">
              <Text lineHeight="1">{name.toLocaleUpperCase("tr")}</Text>
            </Box>

            <HStack d="flex" spacing="3">
              <Badge fontSize="1rem" colorScheme="teal">
                {model}
              </Badge>
              {/* <Badge fontSize="1rem" colorScheme="red">
                {price}₺
              </Badge> */}
              <Badge fontSize="1rem" colorScheme="red">
                ÜRÜN KOD: {id.substring(0, 6)}
              </Badge>
            </HStack>
            {/* <ButtonGroup size="sm" isAttached variant="outline">
              <IconButton onClick={() => setCount(count - 1)} aria-label="Add to friends" icon={<MinusIcon />} />
              <Center mx="-px" userSelect="none" borderWidth="1px" px="2" borderColor="gray.100" fontWeight="600">
                {count} Adet
              </Center>
              <IconButton onClick={() => setCount(count + 1)} aria-label="Add to friends" icon={<AddIcon />} />
            </ButtonGroup> */}
            <Box>
              <Box>
                <Button
                  as="a"
                  colorScheme="green"
                  target="_blank"
                  href={`https://api.whatsapp.com/send?phone=${number}&text=${model} modeli için  ${name}(ürün kodu ${id.substring(
                    0,
                    6
                  )}) sipariş etmek istiyorum.`}
                >
                  WHATSAPP İLE SİPARİŞ VER
                </Button>
              </Box>
              <Box mt="10px">
                <Button as="a" colorScheme="blue" target="_blank" href={`mailto:${mail}`}>
                  MAIL İLE SİPARİŞ VER
                </Button>
              </Box>
            </Box>
          </VStack>

          <Others></Others>
        </Box>
      </Grid>
      <Models></Models>
    </Box>
  );
};

export const query = graphql`
  query($id: String!) {
    allItem(filter: { id: { eq: $id } }) {
      edges {
        node {
          name
          id
          model
          code
          price
          gatsbyImage {
            childImageSharp {
              gatsbyImageData(aspectRatio: 0.8)
            }
          }
        }
      }
    }
  }
`;
export default ProductTemplate;
