//@ts-check
import { Grid } from "@chakra-ui/layout";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import ProductItem from "../components/ProductItem";
import { Section } from "../pages";
const Others = () => {
  const models = useStaticQuery(
    graphql`
      query {
        allItem(limit: 6) {
          edges {
            node {
              name
              id
              model
              code
              price
              gatsbyImage {
                childImageSharp {
                  gatsbyImageData(
                    #formats: [AVIF, WEBP, PNG]
                    #placeholder: DOMINANT_COLOR
                    aspectRatio: 1.6
                    #layout: CONSTRAINED
                    #quality: 25
                  )
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <Section name={undefined} value={"DİĞER ÜRÜNLER"}>
      <Grid templateColumns="repeat(auto-fit, minmax(200px,1fr))" gap={3}>
        {models.allItem.edges.map(({ node }) => (
          <ProductItem
            key={node.id + "other"}
            id={node.id}
            gatsbyImage={node.gatsbyImage}
            name={node.name}
            model={node.model}
            price={node.price}
            baseFontSize="0.8rem"
          ></ProductItem>
        ))}
      </Grid>
    </Section>
  );
};

export default Others;
